import React, { useContext } from "react"
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Container
} from "@material-ui/core"
import { Link } from 'gatsby';
import CloseIcon from '@material-ui/icons/Close';
import { Context } from "../common/Context";

import '../assets/styles/components/mobileNav.scss';

const useStyles = makeStyles({
  drawer: {
    height: '100%',
    width: '100%'
  },
  drawerPaper: {
    backgroundColor: 'white'
  },
  close: {
    position: 'absolute',
    top: '0',
    zIndex: '1',
    height: '5rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '0'
  },
  list: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  listItem: {
    justifyContent: 'center',
  },
  text: {
    padding: '0 1rem',
    textAlign: 'center',
  },
  primary: {
    fontSize: '2rem !important'
  }
});

const MobileNav = () => {
  const context = useContext(Context);
  const { state, dispatch } = context;

  const classes = useStyles();

  const handleClose = () => {
    dispatch({ type: "TOGGLE_MOBILE_NAV", payload: false });
  }

  return (
    <Drawer
      anchor='top'
      open={state.isMobileNavOpen}
      onClose={handleClose}
      onBackdropClick={handleClose}
      disableRestoreFocus
      classes={{paperAnchorRight: classes.drawer, paper: classes.drawerPaper}}
    >
      <Container classes={{root: classes.close}}>
        <CloseIcon onClick={handleClose} />
      </Container>

      <div className='mobile-nav-list'>
        <List classes={{root: classes.list}}>
          <ListItem classes={{root: classes.listItem}} button>
            <Link to='/' activeClassName='active'>
              <ListItemText classes={{root: classes.text, primary: classes.primary}} primary='Home' />
            </Link>
          </ListItem>
          <ListItem classes={{root: classes.listItem}} button>
            <Link to='/about' activeClassName='active'>
              <ListItemText classes={{root: classes.text, primary: classes.primary}} primary='About' />
            </Link>
          </ListItem>
          <ListItem classes={{root: classes.listItem}} button>
            <Link to='/services' activeClassName='active'>
              <ListItemText classes={{root: classes.text, primary: classes.primary}} primary='Services' />
            </Link>
          </ListItem>
          <ListItem classes={{root: classes.listItem}} button>
            <Link to='/projects' activeClassName='active'>
              <ListItemText classes={{root: classes.text, primary: classes.primary}} primary='Projects' />
            </Link>
          </ListItem>
          <ListItem classes={{root: classes.listItem}} button>
            <Link to='/contact' activeClassName='active'>
              <ListItemText classes={{root: classes.text, primary: classes.primary}} primary='Contact' />
            </Link>
          </ListItem>
        </List>
      </div>
    </Drawer>
  )
}

export default MobileNav;
