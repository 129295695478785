import * as React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  Container,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  useMediaQuery
} from "@material-ui/core"

import Hamburger from "./hamburger"

import '../assets/styles/components/header.scss';
import { theme, colors } from "../common/Theme"

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  navList: {
    display: 'flex'
  },
  navListItem: {
    marginLeft: '1rem',
    marginBottom: 0,
    '&:hover': {
      '& .MuiListItemText-root': {
        color: colors.anthracite,
      },
      '& .MuiListItemText-root::after': {
        width: '100%'
      }
    }
  },
  navListItemText: {
    position: 'relative',
    marginTop: 0,
    transition: 'color .3s ease-in-out',
  }
});

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      imageLarge: file(relativePath: { eq: "syntactful_logo_mono_dark.png" }) {
        childImageSharp {
          gatsbyImageData(height: 64, placeholder: BLURRED, formats: PNG)
        }
      },
      imageSmall: file(relativePath: { eq: "syntactful_logo_mono_dark.png" }) {
        childImageSharp {
          gatsbyImageData(height: 48, placeholder: BLURRED, formats: PNG)
        }
      }
    }
  `)

  const classes = useStyles();
  const shouldDisplaySmallLogo = useMediaQuery(theme.breakpoints.up('sm'));
  const shouldDisplayFullMenu = useMediaQuery(theme.breakpoints.up('middle'));

  const imageLarge = getImage(data.imageLarge);
  const imageSmall = getImage(data.imageSmall);

  const displayMenu = () => {
    if (shouldDisplayFullMenu) {
      return (
        <List classes={{root: classes.navList}}>
          <ListItem classes={{root: classes.navListItem}}>
            <Link to='/' activeClassName='active'>
              <ListItemText classes={{root: classes.navListItemText}} primary='Home' />
            </Link>
          </ListItem>
          <ListItem classes={{root: classes.navListItem}}>
            <Link to='/about' activeClassName='active'>
              <ListItemText classes={{root: classes.navListItemText}} primary='About' />
            </Link>
          </ListItem>
          <ListItem classes={{root: classes.navListItem}}>
            <Link to='/services' activeClassName='active'>
              <ListItemText classes={{root: classes.navListItemText}} primary='Services' />
            </Link>
          </ListItem>
          <ListItem classes={{root: classes.navListItem}}>
            <Link to='/projects' activeClassName='active' partiallyActive={true}>
              <ListItemText classes={{root: classes.navListItemText}} primary='Projects' />
            </Link>
          </ListItem>
          {/*<ListItem classes={{root: classes.navListItem}}>*/}
          {/*  <Link to='/process' activeClassName='active'>*/}
          {/*    <ListItemText classes={{root: classes.navListItemText}} primary='Process' />*/}
          {/*  </Link>*/}
          {/*</ListItem>*/}
          <ListItem classes={{root: classes.navListItem}}>
            <Link to='/contact' activeClassName='active'>
              <ListItemText classes={{root: classes.navListItemText}} primary='Contact' />
            </Link>
          </ListItem>
        </List>
      )
    }

    return <Hamburger />;

  }

  return (
    <header className='top-nav'>
      <Container className='nav-container' classes={{root: classes.container}}>
        <div className='nav-left'>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            <GatsbyImage image={shouldDisplaySmallLogo ? imageLarge : imageSmall} alt='Syntactful logo'/>
          </Link>
        </div>
        <nav role='navigation' className='nav-right'>
          { displayMenu() }
        </nav>
      </Container>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
