import { createMuiTheme, makeStyles } from "@material-ui/core"
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

export const colors = {
  anthracite: '#333',
  // anthracite: '#262626',
  anthraciteDark: '#1a1a1a',
  primaryBlue: '#185766',
  primary0range: '#C67C24',
  accent: '#AD401F',

  greyLight: '#F7F7F7',
  greyDark: '#CCCCCC',
  greyDarker: '#919191',
}

export const createContent = makeStyles({
  content: {
    position: 'relative',
    paddingTop: '6rem',
    paddingBottom: '6rem'
  }
});

const breakpoints = createBreakpoints({});

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      middle: 800,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  props: {
    MuiTypography: {
      variantMapping: {
        subtitle2: 'span'
      }
    }
  },
  palette: {
    primary: {
      main: colors.anthracite
    },
    secondary: {
      main: colors.primary0range
    },
    text: {
      // primary: colors.greyLight,
      // secondary: colors.anthracite,
      primary: colors.anthracite,
      secondary: colors.greyLight,
      info: colors.accent,
      warning: colors.primary0range
    }
  },
  typography: {
    fontFamily: [
      'source sans pro',
      'Roboto',
      'Helvetica neue',
      'sans serif'
    ].join(','),
    h1: {
      fontSize: '2.2rem',
      fontWeight: '500',
      marginBottom: '1.5rem',
      [breakpoints.up('sm')]: {
        fontSize: '3rem'
      }
    },
    h2: {
      fontSize: '1.1rem',
      fontWeight: '300',
      lineHeight: '1.4',
      [breakpoints.up('sm')]: {
        fontSize: '1.2rem'
      }
    },
    h3: {
      fontSize: '1.75rem',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      marginBottom: '1.5rem'
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: '400',
      marginBottom: '1.5rem',
    },
    h5: {
      // textTransform: 'uppercase',
      fontSize: '1.2rem',
      fontFamily: [
        'source sans pro',
        'Roboto',
        'Helvetica neue',
        'sans serif'
      ].join(','),
    },
    h6: {
      fontSize: '1.2rem',
      fontWeight: 300,
      marginBottom: '.2rem'
    },
    subtitle1: {
      fontSize: '1.2rem',
      fontWeight: '300',
      lineHeight: '1.2',
      [breakpoints.up('sm')]: {
        fontSize: '1.4rem'
      }
    },
    body1: {
      fontFamily: [
        'source sans pro',
        'Roboto',
        'Helvetica neue',
        'sans serif'
      ].join(','),
    },
    body2: {
      lineHeight: '1.4rem',
      fontFamily: [
        'source code pro',
        'source sans pro',
        'Roboto',
        'Helvetica neue',
        'sans serif'
      ].join(','),
      fontSize: '.9rem'
    },
    subtitle2: {
      fontFamily: [
        'source sans pro',
        'Roboto',
        'Helvetica neue',
        'sans serif'
      ].join(','),
      fontSize: '1rem'
    },
    caption: {
      color: colors.greyDark,
      paddingRight: '1.1rem'
    }
  },
  overrides: {
    MuiContainer: {
      root: {
        '&.MuiContainer-maxWidthLg': {
          [breakpoints.up('lg')]: {
            maxWidth: '1440px'
          }
        }
      }
    },
    MuiGrid: {
      'spacing-xs-1': {
        // 100% width of parent
        // + 48px to compensate for margin of parent
        // + (#margin - padding of parent) to compensate for the surplus on the left of the view port
        width: 'calc(100% + 32px + (4px - 16px))',
        margin: '-4px 0 0 -4px',
        [breakpoints.up('sm')]: {
          width: 'calc(100% + 48px + (4px - 24px))',
          margin: '-4px 0 0 -4px',
        }
      },
      'spacing-xs-2': {
        width: 'calc(100% + 32px + (8px - 16px))',
        margin: '-8px 0 0 -8px',
        [breakpoints.up('sm')]: {
          width: 'calc(100% + 48px + (8px - 24px))',
          margin: '-8px 0 0 -8px',
        }
      },
      'spacing-xs-3': {
        width: 'calc(100% + 32px + (12px - 16px))',
        margin: '-12px 0 0 -12px',
        [breakpoints.up('sm')]: {
          width: 'calc(100% + 48px + (12px - 24px))',
          margin: '-12px 0 0 -12px',
        }
      },
      'spacing-xs-4': {
        width: 'calc(100% + 32px + (16px - 16px))',
        margin: '-16px 0 0 -16px',
        [breakpoints.up('sm')]: {
          width: 'calc(100% + 48px + (16px - 24px))',
          margin: '-16px 0 0 -16px',
        }
      },
      'spacing-xs-5': {
        width: 'calc(100% + 32px + (20px - 16px))',
        margin: '-20px 0 0 -20px',
        [breakpoints.up('sm')]: {
          width: 'calc(100% + 48px + (20px - 24px))',
          margin: '-20px 0 0 -20px',
        }
      },
      'spacing-xs-6': {
        width: 'calc(100% + 32px + (24px - 16px))',
        margin: '-24px 0 0 -24px',
        [breakpoints.up('sm')]: {
          width: 'calc(100% + 48px + (24px - 24px))',
          margin: '-24px 0 0 -24px',
        }
      },
      'spacing-xs-7': {
        width: 'calc(100% + 32px + (28px - 16px))',
        margin: '-28px 0 0 -28px',
        [breakpoints.up('sm')]: {
          width: 'calc(100% + 48px + (28px - 24px))',
          margin: '-28px',
        }
      },
      'spacing-xs-8': {
        width: 'calc(100% + 32px + (32px - 16px))',
        margin: '-32px 0 0 -32px',
        [breakpoints.up('sm')]: {
          width: 'calc(100% + 48px + (32px - 24px))',
          margin: '-32px 0 0 -32px',
        }
      },
      'spacing-xs-9': {
        width: 'calc(100% + 32px + (36px - 16px))',
        margin: '-36px 0 0 -36px',
        [breakpoints.up('sm')]: {
          width: 'calc(100% + 48px + (36px - 24px))',
          margin: '-36px 0 0 -36px',
        }
      },
      'spacing-xs-10': {
        width: 'calc(100% + 32px + (40px - 16px))',
        margin: '-40px 0 0 -40px',
        [breakpoints.up('sm')]: {
          width: 'calc(100% + 48px + (40px - 24px))',
          margin: '-40px 0 0 -40px',
        }
      },
    },
    MuiButton: {
      root: {
        borderRadius: '200px',
        borderColor: colors.accent,
        borderWidth: 2,
        borderStyle: 'solid',
        transition: 'background-color .3s ease-in-out'
      },
      label: {
        paddingLeft: '.5rem',
        paddingRight: '.5rem',
        paddingTop: '.2rem',
        paddingBottom: '.2rem',
        fontWeight: 'bold'
      },
      contained: {
        color: colors.greyLight,
        backgroundColor: colors.accent,
        '&:hover': {
          backgroundColor: colors.accent
        }
      }
    },
    MuiCard: {
      root: {
        backgroundColor: colors.greyLight,
        borderRadius: '0px',
        marginBottom: '1rem',
      }
    },
    MuiCardContent: {
      root: {
        padding: '2rem 1.5rem',
        '&:last-child': {
          paddingBottom: '2rem'
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: colors.anthracite
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderColor: `rgba(204,204,204,0.2) !important`
        }
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: colors.anthracite
      }
    },
    MuiAlert: {
      root: {
        padding: '0 1rem'
      },
      outlinedSuccess: {
        border: `1px solid #4caf50`
      },
      message: {
        color: '#4caf50'
      },
      action: {
        color: '#4caf50'
      }
    }
  }
});
