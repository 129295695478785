/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from "../common/Theme"
import Header from "./header"
import Footer from "./footer"
import { Context, initialState, reducer } from "../common/Context"
import { useReducer } from "react"
import MobileNav from "./mobileNav"
import Seo from "./seo"
import CookieConsent from 'react-cookie-consent';

import "./layout.css"
import '../assets/styles/components/cookieConsent.scss'
import '../assets/styles/main.scss'

const Layout = ({ mainClassName, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ThemeProvider theme={theme}>
      <Context.Provider value={{ state, dispatch }}>
        <Seo title='Software Development, Design & Consulting' />

        <Header/>
        <MobileNav />

        <div className='page'>
          <main className={`main${mainClassName ? ` ${mainClassName}` : ''}`}>{children}</main>

          <Footer/>



          <CookieConsent
            location='none'
            disableStyles
            buttonText='Accept all'
            enableDeclineButton
            flipButtons
            declineButtonText="Accept minimal cookies"
            cookieName="gatsby-gdpr-google-analytics"
            contentClasses='CookieConsent-content'
            buttonClasses='CookieConsent-accept'
            declineButtonClasses='CookieConsent-decline'
            buttonWrapperClasses='CookieConsent-buttons'
          >
            <p>Cookies help us to improve our services.</p>
            <p>Select which cookies you wish to use.</p>
          </CookieConsent>
        </div>

      </Context.Provider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
