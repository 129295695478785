import React from 'react';

export const initialState = {
  isMobileNavOpen: false
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_MOBILE_NAV':
      return { ...state, isMobileNavOpen: action.payload}
    default:
      return state;
  }
}

export const Context = React.createContext({});
