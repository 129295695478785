import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Container, Typography, makeStyles } from "@material-ui/core"

import '../assets/styles/components/footer.scss';

const useStyles = makeStyles({
  footerContainer: {
    paddingTop: '6rem',
    paddingBottom: '2rem',
  }
})

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "syntactful_icon_mono.png" }) {
        childImageSharp {
          gatsbyImageData(height: 32, placeholder: BLURRED, formats: PNG)
        }
      }
    }
  `)

  const classes = useStyles();

  const image = getImage(data.image);

  return (
      <footer className='footer'>
        <Container classes={{root: classes.footerContainer}}>
          <div className='footer-content'>
            <div className='footer-column'>
              <GatsbyImage image={image} alt='Syntactful logo' className='footer-logo' />

              <div className='footer-column-content'>
                <Typography color='textSecondary'>
                  Syntactful specializes in digital design, software development and consulting.
                </Typography>
              </div>
            </div>

            <div className='footer-column'>
              <div className='footer-column-header'>
                <Typography color='textSecondary' variant='subtitle2'>Services</Typography>
              </div>

              <div className='footer-column-content'>
                <div className='footer-column-content-line'>
                  <Link to='/services/design'>
                    <Typography variant='subtitle2' color='textSecondary'>Design</Typography>
                  </Link>
                </div>
                <div className='footer-column-content-line'>
                  <Link to='/services/development'>
                    <Typography variant='subtitle2' color='textSecondary'>Development</Typography>
                  </Link>
                </div>
                <div className='footer-column-content-line'>
                  <Link to='/services/consulting'>
                    <Typography variant='subtitle2' color='textSecondary'>Consulting</Typography>
                  </Link>
                </div>
              </div>
            </div>

            <div className='footer-column'>
              <div className='footer-column-header'>
                <Typography color='textSecondary' variant='subtitle2'>Contact</Typography>
              </div>

              <div className='footer-column-content'>
                <div className='footer-column-content-line'>
                  <a href='mailto:info@syntactful.com' className='hoverable'>
                    <Typography variant='subtitle2' color='textSecondary'>info@syntactful.com</Typography>
                  </a>
                </div>
                <div className='footer-column-content-line'>
                  <a href='tel:+32496685837' className='hoverable'>
                    <Typography variant='subtitle2' color='textSecondary'>+32 496 68 58 37</Typography>
                  </a>
                </div>
                <div className='footer-column-content-line'>
                  <div>
                    <Typography color='textSecondary' variant='subtitle2'>Begijnhof 17</Typography>
                  </div>
                  <div>
                    <Typography color='textSecondary' variant='subtitle2'>2320 Hoogstraten, Belgium</Typography>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className='footer-copyright'>
            <div>
              <Typography variant='caption'>&copy;&nbsp;{new Date().getFullYear()}&nbsp;-&nbsp;Syntactful</Typography>
            </div>
            <div>
              <Typography variant='caption'>
                <Link to='/terms-and-conditions'>
                  Terms&nbsp;&&nbsp;Conditions
                </Link>
              </Typography>
            </div>
            <div>
              <Typography variant='caption'>
                <Link to='/privacy-policy'>
                  Privacy&nbsp;Policy
                </Link>
              </Typography>
            </div>
          </div>
        </Container>
      </footer>
  )
}

export default Footer;
