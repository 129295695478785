import React, { useContext } from "react"
import '../assets/styles/components/hamburger.scss';
import { Context } from "../common/Context"

const Hamburger = () => {
  const context = useContext(Context);
  const { dispatch } = context;

  const handleClick = () => {
    dispatch({ type: "TOGGLE_MOBILE_NAV", payload: true });
  }

  return (
    <button onClick={handleClick} className='hamburger' name='mobile-navigation-toggle' aria-label='Open navigation menu'>
      <span className='line' aria-hidden={true} />
      <span className='line' aria-hidden={true} />
      <span className='line' aria-hidden={true} />
    </button>
  )
}

export default Hamburger;
